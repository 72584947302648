import React, { useState, useEffect, useRef } from 'react'
import TabSwtich from "../../../../Common/fTabSwitch";
import { SearchableSelect } from "../../../../Common/Class/Select/SearchableSelec2";
import axios, { get, post } from "../../../../../Model/Network/Config/Axios";
import { FloatInputButton, FunctionalInput } from "../../../../Common/fInputs";
import PhotoTile from "../../../../Common/fPhoto";
import Track from "../../../../Common/fTrack";
import Moment from "moment";
import { handleError, showToast } from "../../../../Common/fToast/ToastCallback";
import { SidePane } from "../../../../Common/fSidePane/RightPane";
import Invoice from "./fInvoice";
import Tds from './Tds';
import { Spinner } from '../../../../../Components/Common/fLoader'
import moment from 'moment';
import PODetails from '../../PoDetails'
import { Modal } from '../../../../Common/fModal/Modal';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import { NormalTable } from '../../../../Tables/NormalTable/NormalTable';
import { KeyValue } from '../../../../Common/Components/KeyValue';
import { PageLoader } from '../../../../Common/Components/PageLoader';
import { ViewAttachment } from '../../../../Common/ViewAttachments';
import { SupportTicket } from '../../../../Common/SupportTicket';
import { useSelector } from 'react-redux';
import TwoBRecon from './2bRecon';
import { ViewLedger } from '../../../fSettlements/fSettlements/fVendorLedger/VendorLedger/Helper/ViewLedger';
import { Allocation } from '../../../../Common/Allocation';
import { Integration } from '../../../../Common/Integration';
import { DropdownModal } from '../../../../Common/fModal/DropdownModal';
import { getBaseUrl } from '../../../../../Model/Network/Config/DecentralizeWrapper';
import { Form } from '../../../../Common/Class/Form';
import IrnDetails from './IrnDetails';
import useDateFormat from '../../useDateFormat';
import { CostAllocation } from '../../Submit/MultiSubmitInvoice/CostAllocation';
import ToggleSwitch, { ToggleSwitch2 } from '../../../../Common/fSwitch';
import { MultipleAttachmentUploaderModal } from '../../../../Common/Class/FloatLabelInput/MultipleFileAlone';
import { PhotoName } from '../../../../Common/fPhoto/PhotoName';
import { FloatSelect } from '../../../../Common/Class/FloatLabelInput';
import { MultiSelect } from "react-multi-select-component";
import { FloatFile } from '../../../../Common/Class/FloatLabelInput/FloatFile';
import Attachments from '../../../../Common/Components/Attachments';
import FindMaster from '../../../../Common/Class/Form/Master';
import FloatFile2 from '../../../../Common/Class/FloatLabelInput/FloatFile2';
import { ReadOnly } from '../../../../Common/fCard/ButtonOutline/ReadOnly';
import Contract from '../Contract';
import _, { isArray } from 'lodash';
import BankAdd from '../BankAdd';
import { BAJAJ } from '../../../fSettlements/fReimbursements/Voucher/CompanyVoucher';

export default function India(props) {
	let { details, onReload, id, debitNote, debitNoteDetails ,enableAddLineItem} = props;
	console.log("DETAILS : ",details)
	const fileExtensions = ['.pdf', '.csv', '.docx', 'png', '.jpg', '.jpeg', '.xls', '.xlsx'];
	 // excludes Approved on tag  on timeline status 
	const excludeStatus = ['REVERTED','HOLD','UNHOLD','DECLINED']
    const timelineLogStatus = ['HOLD','UNHOLD','REVERTED','COMPLETED','CANCELLED']


	const [getTab, setTab] = useState(1);
	const [getVendor, setVendor] = useState('');
	const [vendorAddress, setVendorAddress] = useState([]);
	const [vendorAddressFilter, setVendorAddressFilter] = useState([]);
	const [toggleAddress, setToggleAddress] = useState(undefined);
	const [selectedVendorAddress, setSelectedVendorAddress] = useState('');
	const [getVendors, setVendors] = useState([]);
	const [getBillOffice, setBillOffice] = useState('');
	const [getShipOffice, setShipOffice] = useState('');
	const [getOffices, setOffices] = useState([]);
	const [Settlements, setSettlements] = useState(undefined);
	const [partial,setPartial] = useState(false);
	const [recall,setRecall] = useState(false);
	const [advance,setAdvance] = useState(false);
	const [loading,setLoading]  = useState(false);
	const [attachmentMode,setAttachmentMode] = useState(false);
	const [recallPartial,setRecallPartial] = useState(false);
	const [decline,setDecline] = useState(false);
	const {gst2bRecon,costAllocation,mandatoryCostAllocation,creditNote,debitNote:debitNotePlugin,hsnMandatorySingleLineInvoice,tdsAtLineItem,budgetingV2,tdsAtLineItemInContract} = useSelector(state=>state.auth.config.plugins);
	const [enableCostAllocation,setEnableCostAllocation] = useState(false);
	const [repost,setRepost] = useState(false);
	const [allocationType,setAllocationType]= useState(undefined);
	const [invoices,setInvoices] = useState([]);
	const [btnloading,setBtnLoading] = useState(false);
	const [ledger,setLedger] = useState(undefined);
	const [InvoiceForm,setInvoiceForm] = useState([]);
	const [LineItemForm,setLineItemForm] = useState([]);
	const [prefill,setPrefill]=useState();
	const [allIndependentCN,setAllIndependentCN] = useState();
	const [master,setMaster] = useState({
		bill_to:details.address.title
	});
	const [masterLI,setMasterLI] = useState({
			bill_to:details.address.title
	});
	const [withdraw,setWithdraw] = useState(undefined);
	const {tenant} = useSelector(state=>state.auth.config)
  
	let COSTALLOCATION = useRef();

	let { status } = details;
	let FORM = useRef();
	let CUSTOMFORM = useRef([]);
	let settlementFormate = useDateFormat("DD-MM-YYYY");

	let po = props.details.po && props.details.po.poId;
	let REMARKS = useRef(), REMARKS1 = useRef(),WITHDRAW_REMARK= useRef() ,AMOUNT = useRef(), REMARK = useRef(), ALLOCATION = useRef();

	let { isRcm} = typeof details.content === 'string' ? JSON.parse(details.content) : details.content;
	const [rcm,setRcm] = useState(isRcm);
	const [activeRooms,setActiveRooms] = useState([]);
    const [creditNotes,setCreditNotes] = useState([]);
    const [debitNotes,setDebitNote] = useState([]);
	const [grn,setGrn] = useState([]);
	const [customTags,setCustomTags] = useState({});
    const [invoicePreview,setInvoicePreview] = useState(false)
	
	useEffect(() => {
		fetchSettlements(id)
		fetchInvoiceCategoryForm();
		fetchInvoiceLineItemForm();
		fetchGrn(id);
		getAllIndependentCN();
	}, []);
 
	useEffect(() =>{
		fetchRoom()
		fetchCreditNote(id)
		fetchDebitNote(id)
		if(details.vendor){
			// Fetch vendor  address  to update vendor Address
			getVendorDetails()
		}
	},[])

	useEffect(() => {
		let departmentName = prefill && prefill["department"];
		if (['PIRAMAL','prml'].includes(tenant) && InvoiceForm.length > 0 && departmentName) {
			let dep = InvoiceForm.find((e) => e.fieldId === "department");
			if (dep) {
				let id = details.address.id;
				if(id){
					get(`heirarchy/offices/${id}`, (e, r) => {
						if (r && r.departments && r.departments.length > 0) {
							let department = r.departments.find((e) => e.name === departmentName);
							if (department) {
								get(`heirarchy/department/${department.id}/details`, (e, r) => {
									if (r) {
										setCustomTags(r.tags);
									}
								});
							}
						}
					});
				}
			}
		}
	}, [InvoiceForm, prefill]);

	const  getVendorDetails = () =>{
		let vendorId = details.vendor.id
		get(`vendor/contacts/${vendorId}/details`,(e,r)=>{
            if(r){
				let findDefault = r.addresses.find((e) => e.id === details.vendor.address.id) 
				let addresses = r.addresses.map(item => ({
					value: item.id,
					label: item.address,
					des: item.gstin
				}))
                setVendorAddress(addresses);
                setVendorAddressFilter(addresses);

				if(findDefault){
					setSelectedVendorAddress({
						value: findDefault.id,
						label: findDefault.address,
						des: findDefault.gstin
					});
				}
            }else{
            }
        })
	}

	const fetchGrn = () => {
		get(`vendor/procurement/grn/allGrn?invoice_id=${id}&offset=0`,(e,r)=>{
            if(r){
                setGrn(r.responseItems);
            }else{
               // handleError(e)
            }
        })
	}



	const fetchCreditNote = (id) =>{
        get(`vendor/vendor/creditNote/getAll`,(e,r)=>{
            if(r){
                setCreditNotes(r.creditNotes);
            }else{
                handleError(e)
            }
        },{filter:[`${id}@t0.invoice_id`]})
    }

	const getAllIndependentCN = () => {
		let vendorId = details.vendor.id
		get(`vendor/vendor/creditNote/fetchAllIndependentCreditNotes?vendorId=${vendorId}`,(e,r)=>{
			if(r){
				let parsed =  (details.content && typeof details.content === 'string') ? JSON.parse(details.content): details.content
				setAllIndependentCN(r.creditNotes.filter(el => (el.status === "COMPLETED" && el.amount <= (details.invoice.amount-parsed.creditNoteTotal))));
			}
		})
	}

	const syncCreditNote = (creditNote) => {
		post(`vendor/vendor/creditNote/${details.invoice.counterId}/syncCreditNote`,{'creditNoteId':Number(creditNote.id)},(e,r)=>{
			if(r){
				showToast({
					type:'success',
					message:'Credit Note Synced Sucessfully'
				})
				getAllIndependentCN();
				props.onReload();
			}else{
				handleError(e);
			}
		})
	}

	const fetchDebitNote = (id) =>{
        get(`vendor/vendor/debitNotes/all`,(e,r)=>{
            if(r){
                setDebitNote(r.debitNotes);
            }else{
                handleError(e)
            }
        },{filter:[`${id}@t0.invoice_id`]})
    }


    const fetchRoom = () => {
        get(`workspace/seekClarification/getMyRooms`,(e,r)=>{
            if(r){
                setActiveRooms(r.rooms.filter((e => e.status === 'ACTIVE')));
            }
        },{
		type:'vendor-invoice',
		id})
    }
	

	const fetchInvoiceCategoryForm = () => {
		let singleLine = props.details.tags['inv_type']?props.details.tags['inv_type']==='single':false;
		if(singleLine) {
			let service = {};
			get('vendor/config/services',(e,r)=>{
				if(r) {
					r.items.forEach(el=>{
						service[el.name] = el.id;
					})
					get(`forms/vendor::${service[details.invoice.service]}`, (e, r) => {
						if (r) {
							setInvoiceForm(r.forms);
							let prefilldata = {};
							r.forms.forEach(el => {
								let val = details.tags[el.fieldId];
								prefilldata[el.fieldId] = val;
							})
							setPrefill(prefilldata);
						} else {
							handleError(e);
						}
					})
				}else {
					handleError(e);
				}
			})
		} else {
			get(`forms/vendor::category::${details.invoice.categoryId}`, (e, r) => {
				if (r) {
					setInvoiceForm(r.forms);
					let prefilldata = {};
					r.forms.forEach(el => {
						let val = details.tags[el.fieldId];
						prefilldata[el.fieldId] = val;
					})
					setPrefill(prefilldata);
				} else {
					handleError(e);
				}
			})
		}

		
	}
	const fetchInvoiceLineItemForm = () => {
		get(`forms/invoice::item::${details.invoice.categoryId}`, (e, r) => {
			if (r) {
				setLineItemForm(r.forms);
			} else {
				handleError(e);
			}
		})
	}

	useEffect(() => {
		setInvoices(details.invoice.items);
	}, [props.details])



	useEffect(()=>{
		if(status && status==='APPROVAL') {
			fetchRoom();
			FORM.current && FORM.current.loadForm(`admin::invoice::category::${details.invoice.categoryId}`);
			CUSTOMFORM.current && CUSTOMFORM.current.forEach((ref) => {
				ref.loadForm(`finance::invoice::item::${details.invoice.categoryId}`)
			})
		}
	}, [status,props.details])

	useEffect(() => {
		if (getTab === 1 && status === 'APPROVAL') {
			fetchRoom();
			FORM.current.loadForm(`admin::invoice::category::${details.invoice.categoryId}`);
			CUSTOMFORM.current.forEach((ref) => {
				ref.loadForm(`finance::invoice::item::${details.invoice.categoryId}`)
			})
		}
	}, [getTab])

	const handleFormRef = (ref, index) => {
		CUSTOMFORM.current[index] = ref;
	};

	const  checkTypeAsAdmin =(obj, status)  => {
		for (let i = 0; i < obj.values.length; i++) {
			if (
				obj.values[i].key === "Type" &&
				obj.values[i].value === "ADMIN" &&
				status !== "APPROVAL" &&
				status !== "POHOLD" &&
				status !== "COMPLETED" &&
				status !== "CANCELLED"
			) {
				return true;
			}
		}
		return false;
	}

	const approveHandler = async () => {
		let data = {};
		let flag = true;
		let count = 0;
		let internalCount = 0;
		// to get custome line field values
		CUSTOMFORM.current.forEach((ref) => {
			flag = false;
			ref.getKeyValue(val => {
				if (Object.keys(val).length) {
					count++;
				}
				flag = true;

			});

		})

		if (!flag) {
			showToast({
				type: 'error',
				message: "Please check line item custom first"
			})
			return
		}


		if (count > 0) {

			details.invoice.items.forEach((i, index) => {
				let formValue = {};
				CUSTOMFORM.current[index].getKeyValue(val => {
					formValue = { ...val }
				})
				post(`vendor/invoicing/${details.invoice.id}/finance.item.form`, { "itemId": i.id, "itemForms": { ...formValue } }, (e, r1) => {
					if (r1) {
						internalCount++;
						if (internalCount === count) {

							FORM.current.getKeyValue(val => {
								data.forms = { ...data.forms, ...val };
								setBtnLoading(true);
								post(`/settlements/vendor/invoices/${details.invoice.id}/approve`, data, (e, r) => {
									if (r) {
										setBtnLoading(false);
										props.onReload();
										fetchSettlements(id)
										showToast({
											type: 'success',
											message: 'Approved'
										})
									} else {
										setBtnLoading(false);
										handleError(e)
									}
								})
							})

						}
					} else {
						setBtnLoading(false);
					}
				})
			})
		}




		if (flag) {
			if (count === 0) {
				FORM.current.getKeyValue(val => {
					data.forms = { ...data.forms, ...val };
					setBtnLoading(true);
					post(`/settlements/vendor/invoices/${details.invoice.id}/approve`, data, (e, r) => {
						if (r) {
							props.onReload();
							fetchSettlements(id)
							showToast({
								type: 'success',
								message: 'Approved'
							})
							setBtnLoading(false);
						} else {
							setBtnLoading(false);
							handleError(e);

						}
					})
				});
			}

		} else {
			showToast({
				type: 'error',
				message: "Please check line item custom first"
			})
		}
	}

	const fetchSettlements = (ID) => {
		get(`settlements/settlements/invoice/${ID}/details`, (e, r) => {
			if (r) {
				console.log(r);
				setSettlements(r);
				setLoading(false);
			} else if (e) {
				if (e.data.message === 'Settlement not found') {
					setSettlements({ settlements: [], settled: false })
				} else {
					handleError(e)
				}
				setLoading(false);
			}
		})
	}


	const onRcmChange = (val) =>{
		setRcm(val)
		post(`/vendor/invoicing/${id}/details.change`, {
			 "key":"rcm",
			  value:`${val}`
			 }, (e, r) => {
			if (r) {
				setVendor('');
				onReload()
				showToast({
					type: 'success',
					message: 'Invoice Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const updateInvoice = (field, value) => {

		let key = '';
		switch (field) {
			case 'vendorAddress':
				post(`/vendor/invoicing/${id}/change`, { key: 'vendorAddress',value: String(selectedVendorAddress.value) }, (e, r) => {
					if (r) {
						setToggleAddress(undefined);
						onReload()
						showToast({
							type: 'success',
							message: 'Vendor address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'vendor':
				post(`/vendor/invoicing/${id}/vendor.change`, { vendor: getVendor.value }, (e, r) => {
					if (r) {
						setVendor('');
						onReload()
						showToast({
							type: 'success',
							message: 'Vendor Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'id':
				key = 'invoice';
				break;
			case 'bill':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'billing',
					value: String(getBillOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setBillOffice('');
						showToast({
							type: 'success',
							message: 'Billing Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'ship':
				post(`/vendor/invoicing/${id}/change`, {
					key: 'address',
					value: String(getShipOffice.value)
				}, (e, r) => {
					if (r) {
						onReload()
						setShipOffice('');
						showToast({
							type: 'success',
							message: 'Shipping Address Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;

			case 'date':
				post(`/vendor/invoicing/${id}/change`, {
					key: "date",
					value: Moment(value, "YYYY-MM-DD").format('DD-MM-YYYY')
				}, (e, r) => {
					if (r) {
						onReload();
						showToast({
							type: 'success',
							message: 'Invoice Date Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;
			case 'due':
				post(`/vendor/invoicing/${id}/change`, {
					key: "due",
					value: Moment(value, "YYYY-MM-DD").format('DD-MM-YYYY')
				}, (e, r) => {
					if (r) {
						onReload();
						showToast({
							type: 'success',
							message: 'Invoice Date Updated'
						})
					} else if (e) {
						handleError(e);
					}
				});
				return;

			case 'base':
				key = 'base';
				break;
			case 'tds':
				key = 'tds.fixed';
				break;
			case 'sgst':
				key = 'sgst';
				break;
			case 'igst':
				key = 'igst';
				break;
			case 'cgst':
				key = 'cgst';
				break;
			case 'roundOff':
				key = 'roundOff';
				break;
			case 'discount':
				key = 'discount';
				break;
			default:
				return;
		}

		post(`/vendor/invoicing/${id}/change`, { 'key': key, 'value': value }, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Field Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const updateInsight = (key, val) => {
		post(`/vendor/invoicing/${id}/change`, { 'key': key, 'value': val }, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Insight Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}

	const updateInsightLineItem = (id1, key, val) => {
		post(`/vendor/invoicing/${id}/details.change`, { 'key': key, 'value': val, itemId: id1 }, (e, r) => {
			if (r) {
				onReload()
				showToast({
					type: 'success',
					message: 'Insight Updated'
				})
			} else if (e) {
				handleError(e);
			}
		});
	}


	const renderInvoiceCustomForm = (item, index) => {
		let type = item.type.toLowerCase();
		let prefillData = prefill[item.fieldId];
		if (type === 'date' && prefillData) {
			const parsedDate = moment(prefillData, 'YYYY-MM-DD', true);
			if (!parsedDate.isValid()) {
				prefillData = Moment(prefillData, 'DD-MM-YYYY').format('YYYY-MM-DD');
			}
		}
		if ((type === 'email' || type === 'number' || type === 'text' || type === 'date')) {
			return (
				<FloatInputButton
					maxLength={item.max}
					min={item.min}
					key={item.id}
					title={item.name}
					placeholder={prefillData || ""}
					type={item.type.toLowerCase()}
					onSubmit={(val) => {
						prefill[item.fieldId] = val;
						updateInsight(item.fieldId, val);
						onReload()
					}}
				/>)
		} else if (type === "select") {
			return (
				<div key={index} className="full-width">
					<FloatSelect
						value={prefillData ? { key: prefillData, value: prefillData } : {}}
						firstValue={true}
						key={index}
						title={item.name}
						onChange={(val) => {
							prefill[item.fieldId] = val;
							updateInsight(item.fieldId, val);
						}}
						items={item.values}
					/>
				</div>
			)
		}
		else if (type === "multiselect") {
			return (
				<>
					<label className="floating-top-label" >{item.name}:</label>
					<MultiSelect
						options={item.values.map(j => ({ value: j.value, label: j.key }))}
						value={prefillData ? prefillData.split(';').map(val => { return { value: val, label: val } }) : []}
						onChange={(selected) => {
							selected = selected.filter(el => el.value !== "");
							prefill[item.fieldId] = selected.length ? selected.map(el => el.value).join(';') : '';
							updateInsight(item.fieldId, selected.length ? selected.map(el => el.value).join(';') : '');
						}}
						labelledBy={item.name}
					/>
				</>
			)
		}
		else if (type === 'file') {
			return (
				<>
					{!prefillData ? <FloatFile
						key={index}
						title={item.name}
						required={item.required}
						placeholder={`Select File`}
					/> :
						<><label className="floating-top-label" >
							{
								item.name
							}:
						</label>
							<Attachments
								fileName={"Attachment"}
								multiple={true}
								editable={false}
								attachments={[prefillData]}
								onDelete={(val) => {
									prefill[item.fieldId] = "";
									updateInsight(item.fieldId, "");
								}}
							/>
						</>
					}
				</>
			)
		}
		else if (type === 'master') {
			return (
				<FindMaster
					key={index}
					title={item.name}
					required={item.required}
					placeholder={`Search the ${item.placeholder}`}
					fieldId={item.fieldId ? item.fieldId : item.name}
					details={item}
					defaultValue={prefillData ? prefillData : ""}
					onUpdate={(i, next) => {
						let tempMaster = { ...master };
						let key = next
						tempMaster[key] = i[key];
						setMaster(tempMaster);
						prefill[item.fieldId] = i[key];
						updateInsight(item.fieldId, i[key]);
					}}
					master={master}
				/>
			)
		}
	}

	// const upload = (file,i) => {
	// 	post(`vendor/invoicing/${id}/attachment`, {file:file,name:`${i}` }, (e, r) => {
	// 	  if (r) {
	// 		onReload();
	// 		showToast({
	// 		  type: "success",
	// 		  message: "File Uploaded successfully",
	// 		});
	// 	  }
	// 	});
	//   };


	const renderTags = (item, index) => {
		if (details.status === "APPROVAL") {
			let place = InvoiceForm.findIndex(f => (f.fieldId === item[0]));
			if (place > -1) return;
		}
		const isFile = fileExtensions.some(extension => item[1].includes(extension));
		if (isFile) {
			return (
				<div key={`Tag-${index}`} style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
					<FloatFile2
						fileUrl={item[1]}
						key={item[0]}
						title={item[0]}
						// onUpload={(val) => upload(val,j)}
						placeholder={`Select File`}
					/>
				</div>
			)
		}
		return (
			<div key={`Tag-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
				<FloatInputButton style={{ width: '100%' }} onSubmit={(val) => { updateInsight(item[0], val) }} title={item[0]} placeholder={item[1]} />
			</div>
		)
	}

	const mapLineItemTag = (item, index) => {
		return (
			<div key={index} style={{ border: '1px solid var(--border-color)', padding: 12, borderRadius: 12, marginTop: 20 }}>
				<FloatInputButton disabled={true} style={{ width: '100%' }} title={"Item Name Custom Form"} placeholder={item.name} />

				{

					item.data.map((form, j) => {
						let tempForm = LineItemForm.find(el => el.fieldId === form.id);
						if (details.status === "APPROVAL" && tempForm) {
							let type = tempForm.type.toLowerCase();
							let prefillData = form.value;
							if (type === 'date' && prefillData) {
								const parsedDate = moment(prefillData, 'YYYY-MM-DD', true);
								if (!parsedDate.isValid()) {
									prefillData = Moment(prefillData, 'DD-MM-YYYY').format('YYYY-MM-DD');

								}
							}
							if ((type === 'email' || type === 'number' || type === 'text' || type === 'date')) {
								return (
									<FloatInputButton
										maxLength={tempForm.max}
										min={tempForm.min}
										key={tempForm.id}
										title={tempForm.name}
										placeholder={prefillData || ""}
										type={tempForm.type.toLowerCase()}
										onSubmit={(val) => {
											updateInsightLineItem(item.id, tempForm.fieldId, val);
											onReload()
										}}
									/>)
							} else if (type === "select") {
								return (
									<div key={index} className="full-width">
										<FloatSelect
											value={prefillData ? { key: prefillData, value: prefillData } : {}}
											firstValue={true}
											key={index}
											title={tempForm.name}
											onChange={(val) => {
												updateInsightLineItem(item.id, tempForm.fieldId, val);
											}}
											items={tempForm.values}
										/>
									</div>
								)
							}
							else if (type === "multiselect") {
								return (
									<>
										<label className="floating-top-label" >{tempForm.name}:</label>
										<MultiSelect
											options={tempForm.values.map(j => ({ value: j.value, label: j.key }))}
											value={prefillData ? prefillData.split(';').map(val => { return { value: val, label: val } }) : []}
											onChange={(selected) => {
												selected = selected.filter(el => el.value !== "");
												updateInsightLineItem(item.id, tempForm.fieldId, selected.length ? selected.map(el => el.value).join(';') : '');
											}}
											labelledBy={tempForm.name}
										/>
									</>
								)
							}
							else if (type === 'file') {
								return (
									<>
										{!prefillData ? <FloatFile
											key={index}
											title={tempForm.name}
											required={tempForm.required}
											placeholder={`Select File`}
										/> :
											<><label className="floating-top-label" >
												{
													tempForm.name
												}:
											</label>
												<Attachments
													fileName={"Attachment"}
													multiple={true}
													editable={false}
													attachments={[prefillData]}
													onDelete={(val) => {
														updateInsightLineItem(item.id, tempForm.fieldId, "");
													}}
												/>
											</>
										}
									</>
								)
							}
							else if (type === 'master') {
								return (
									<FindMaster
										key={index}
										title={tempForm.name}
										required={tempForm.required}
										placeholder={`Search the ${tempForm.placeholder}`}
										fieldId={tempForm.fieldId ? tempForm.fieldId : tempForm.name}
										details={tempForm}
										defaultValue={prefillData ? prefillData : ""}
										onUpdate={(i, next) => {
											let tempMaster = { ...masterLI };
											let key = next
											tempMaster[key] = i[key];
											setMasterLI(tempMaster);
											updateInsightLineItem(item.id, tempForm.fieldId, i[key]);
										}}
										master={masterLI}
									/>
								)
							} else {
								return <></>
							}
						} else {
							return (<div key={`Tag-${j}`} style={{ display: 'flex', alignItems: 'center' }}>
								<FloatInputButton style={{ width: '100%' }} onSubmit={(val) => { updateInsightLineItem(item.id, form.id, val) }} title={form.key} placeholder={form.value} />
							</div>)
						}
					})
				}
			</div>
		)
	}

	const mapView = (item, index) => {
		return (
			<div key={index} style={{ border: '1px solid var(--border-color)', padding: 12, borderRadius: 12, marginTop: 20 }}>
				<p style={{ fontSize: 12 }}>{item.title}</p>
				<p style={{ fontSize: 10, marginTop: 2 }}>{item.description}</p>
				<p style={{ fontSize: 10, marginTop: 2 }}>Created At : {Moment(item.date).format('DD-MM-YYYY')}</p>
				{item.values.map((i, j) => {
					const isFile = fileExtensions.some(extension => i.value.includes(extension));
					if (isFile) {
						return (
							<Attachments
								fileName={"Attachment"}
								multiple={true}
								editable={false}
								attachments={[i.value]}
							/>
						)
					}
					return (
						<div key={`Tag-${j}`} style={{ display: 'flex', alignItems: 'center' }}>
							<FloatInputButton disabled={true} style={{ width: '100%' }} title={i.key} placeholder={i.value} />
						</div>
					)
				})}
			</div>
		)
	}

	let tabs = [
		{
			name: 'Format', active: getTab === 1, onClick: () => {
				setTab(1)
			}
		},
		...gst2bRecon ?
			[
				{
					name: '2B Recon', active: getTab === 8, onClick: () => {
						setTab(8)
					}
				}
			] : [],

		{
			name: 'Insight', active: getTab === 2, onClick: () => {
				setTab(2)
			}
		},
		{
			name: 'Timeline', active: getTab === 3, onClick: () => {
				setTab(3)
			}
		},
		...(details.invoice.logs && details.invoice.logs.length>0) ?
			[
				{
					name: 'Logs', active: getTab === 12, onClick: () => {
						setTab(12)
					}
				}
			] : [],
		{
			name: 'Settlements', active: getTab === 4, onClick: () => {
				setTab(4)
			}
		},
		{
			name: 'Ticket', active: getTab === 6, onClick: () => {
				setTab(6)
			}
		},
		...creditNote ? [
		{
			name: 'CN. Note', active: getTab === 10, onClick: () => {
				setTab(10)
			}
		}]:[],
		...debitNotePlugin ? [
			{
				name: 'DB. Note', active: getTab === 11, onClick: () => {
					setTab(11)
				}
			}]:[],
	]

	let { costAllocationDone, integrationData, irnData ,ocrMismatches,ocrData,grnNos,startDate,endDate} = typeof details.content === 'string' ? JSON.parse(details.content) : details.content   //JSON.parse(details.content);
	if (integrationData && Object.keys(integrationData).length) {
		tabs.push({
			name: 'Integration', active: getTab === 7, onClick: () => {
				setTab(7)
			}
		})
	}

	if (po || (props.details && props.details.po && isArray(props.details.po) && props.details.po.length>0)) {
		tabs.push({
			name: 'Po', active: getTab === 5,
			onClick: () => {
				setTab(5)
			}
		})
	}

	if (details.tags && details.tags['contract.id']) {
        tabs.splice(2, 0, {
			name: 'Contract', active: getTab === 9,
			onClick: () => {
				setTab(9)
			}
		});
    }

	let parsed =  (details.content && typeof details.content === 'string') ? JSON.parse(details.content): details.content
	

	const onRepost = () =>{
		post(`workspace/integration/triggerUpdateInvoice`, {
			invoiceId: details.invoice.counterId,
			"data":{
				"sap_retrigger_remarks:":`Manually Re-Triggered by user ar ${moment().format("DD-MM-YYYY HH:mm:ss")}`,
			},
			"postingTriggered":false,
			"postingFailed":false,
			"postingSynced":false
		}, (e,r)=>{
			if(e){
				handleError(e)
			}else{
				onReload()
				setRepost(undefined)
				showToast({
					type: 'success',
					message: 'Invoice Re-post Successfully'
				})
			}
		})
	}

	
	let SGstEnable = true;
	const  vendorGstIn = details && details.vendor && details.vendor.gstin;
	const billGstIn = details && details.address && details.address.gstin;
	if (vendorGstIn && vendorGstIn.length > 2 && billGstIn && billGstIn.length > 2) {
		if (vendorGstIn.slice(0, 2) === billGstIn.slice(0, 2)) {
			SGstEnable= true
		} else {
			SGstEnable = false
		}
	} 

	const renderFiles = (file, index) => {
		if(file.endsWith('.pdf')){
			return <iframe onClick={()=>window.open(file,'_blank')} key={index} src={file} title='Preview' className='pointer mt1' style={{border: 'none', width:'100%', height: '100vh'}}/>
		}else{
			return <img onClick={()=>window.open(file,'_blank')} src={file} alt='Attachment' className='w-100 mt1 pointer'/>
		}
	}

	
	if (loading) {
		return <PageLoader />
	}
	return (
		<SidePane button={false} onClose={() => props.onClose(false)}
				  column={
					  <div style={{ width: '100%' }}>
						  <div style={{ display: 'flex' }}>
							  <ToggleSwitch2 style={{ justifyContent: 'flex-start' }} checked={invoicePreview} callback={setInvoicePreview} size="S" label="View Original Invoice" />
						  </div>
						   {invoicePreview ?
							  <div style={{ marginTop: 10 }}>
								  {details && details.invoice && details.invoice.files && details.invoice.files.map(renderFiles)}
							  </div>
							  :
							  <Invoice
								  budgetingV2={budgetingV2}
								  additionalCharges={details.taxInformation && details.taxInformation.additionalCharges ? details.taxInformation.additionalCharges : []}
								  onReload={onReload}
								  tdsAtLineItem={tdsAtLineItem}
								  tdsAtLineItemInContract={tdsAtLineItemInContract}
								  data={{
									  singleLine: details.tags['inv_type'],
									  enableAddLineItem: enableAddLineItem && !details.po,
									  enableLineItemPlugin: enableAddLineItem,
									  vendor: details.vendor.name,
									  vendorCode: details.vendor.registeredId,
									  panNumber: details.vendor.pan,
									  vendorAddress: details.vendor.address,
									  email: details.vendor.email,
									  vendorDetails: details.vendor,
									  rcVendor: details.vendor.rcVendor,
									  vendor_id: details.vendor.gstin,
									  date: details.invoice.date,
									  due: details.dueOn ? details.dueOn : undefined,
									  Invoice_id: details.invoice.invoiceId,
									  bill_add: details.address.address,
									  ship_add: details.deliverTo.address,
									  bill_to: details.address,
									  ship_to: details.deliverTo,
									  bill_to_gstin: billGstIn,
									  debitNote,
									  debitNoteDetails,
									  rcInvoice: details.invoice.rcInvoice || isRcm,
									  enableCostAllocation: enableCostAllocation,
									  poNumber: props.details && props.details.po && props.details.po.poId,
									  multiPo: props.details && props.details.po && isArray(props.details.po) ? props.details.po.reduce((acc, current) => {
										  if (!acc.some(item => item.poId === current.poId)) {
											  acc.push(current);
										  }
										  return acc;
									  }, []) : null,
									  poId: props.details && props.details.po && props.details.po.id,
									  grnNos: grnNos,
									  creditNoteTotal: parsed && parsed.creditNoteTotal,
									  debitNoteTotal: parsed && parsed.debitNoteTotal,
									  isDebitNote: parsed && parsed.debitNote,
									  isCreditNote: parsed && parsed.creditNote,
									  vendorId: details.vendor.id,
									  table: [
										  ...invoices.map((item => {
											  return ({
												  autoBudgeting: (item.attrs && item.attrs.autoBudgeting) ? item.attrs.autoBudgeting : "yes",
												  desc: item.name ? item.name : item.service,
												  service: item.service,
												  price: item.price,
												  qtty: item.qtty,
												  base_amt: item.base,
												  gross_amt: item.base - (item.base * (details.taxInformation.discount / 100)),
												  tax_amt: item.tax,
												  taxSlab: item.taxSlab,
												  amount: item.amount,
												  total: item.amount,
												  hsn: item.hsn,
												  productId: item.productId,
												  cessAmount: item.cessAmount ? item.cessAmount : 0,
												  debitQty: item.debitQty ? item.debitQty : 0,
												  gstNonCreditable: item.gstNonCreditable || false,
												  costAllocations: item.costAllocations || [],
												  isAllocationAvailable: item.costAllocations && item.costAllocations.length ? true : false,
												  notes: item.notes ? item.notes : undefined,
												  id: item.id,
												  forms: item.data,
												  referenceId: item.referenceId || undefined,
												  orderId: item.orderId,
												  rcItem: item.rcItem,
												  unit: item.unit,
												  budget_id: item.attrs ? item.attrs.budget_id : undefined,
												  budget_status: item.attrs ? item.attrs.budget_status : undefined,
												  budgeted_amount: item.attrs ? item.attrs.budgeted_amount : undefined,
												  reverse_budget_id: item.attrs ? item.attrs.reverse_budget_id : undefined,
												  reverse_budget_status: item.attrs ? item.attrs.reverse_budget_status : undefined,
												  reverse_budgeted_amount: item.attrs ? item.attrs.reverse_budgeted_amount : undefined,
												  tds: (item.tdsAmount || item.tdsRate) && {
													  tdsAmount: item.tdsAmount ? item.tdsAmount : "-",
													  tdsCode: item.tdsCode,
													  tdsRate: item.tdsRate,
													  tdsDesc: item.tdsDesc,
												  },
												  attrs: item.attrs,
												  serviceDesc:item.serviceDesc||''

											  })
										  }))
									  ],
									  cgst: details.taxInformation.cgst,
									  gst: details.taxInformation.gst,
									  sgst: details.taxInformation.sgst,
									  igst: details.taxInformation.igst,
									  tds: details.taxInformation.tds,
									  tcs: details.taxInformation.tcs ? details.taxInformation.tcs : 0,
									  total_tax: details.taxInformation.gst,
									  total_base: details.taxInformation.baseAmount,
									  roundOff: details.taxInformation.roundOff,
									  total_amt: details.taxInformation.total,
									  payable_amt: details.taxInformation.paidAmount,
									  discount: details.taxInformation.discount || 0,
									  discountAmount: details.taxInformation.discountAmount || 0,
									  cessAmount: details.taxInformation.cessAmount,
									  creditNoteTds: parsed.creditNoteTds || 0,
									  status: details.status,
									  settlement: Settlements ? Settlements.settled : true,
									  allSettlement: Settlements ? Settlements.settlements : [],
									  mSettlement: Settlements ? Settlements : false,
									  costAllocationDone: costAllocationDone ? costAllocationDone : false,
									  invoiceStatus: details.status,
									  systemInvoiceId: details.invoice.id,
									  categoryId: details.invoice.categoryId,
									  category: details.invoice.category,
									  asn: details.asn ? details.asn : null,
									  totalAdditionalCharge: details.taxInformation && details.taxInformation.totalAdditionalCharge ? details.taxInformation.totalAdditionalCharge : 0,
									  baseAdditionalCharge: details.taxInformation && details.taxInformation.baseAdditionalCharge ? details.taxInformation.baseAdditionalCharge : 0,
									  grossAdditionalCharge: details.taxInformation && details.taxInformation.grossAdditionalCharge ? details.taxInformation.grossAdditionalCharge : 0,
									  contractId: details.contractId
								  }}
								  updateAllocation={(items, type, index) => {
									  let allInvoice = [...invoices];
									  allInvoice[index].costAllocations = items[index].costAllocations;
									  setAllocationType(type);
									  setInvoices(allInvoice)
								  }}
								  reload={onReload}
								  init={() => {
									  props.onReload()
									  if (costAllocationDone) {
										  ALLOCATION.current.INIT();			// initiate allocation again
									  }
									  fetchInvoiceCategoryForm();
									  fetchInvoiceLineItemForm();
								  }}
							  />
						  }
				</div>
			}>
			<div className='FadeRight-Effect' style={{ fontSize: '12px', width: '100%', minWidth: '400px' }}>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 35 }}>
					<div style={{ flex: 2 }}>
						<div style={{ fontWeight: 600, fontSize: '18px', lineHeight: '1.3' }}>Invoice</div>
						<div style={{
							color: 'var(--text-color)',
							fontWeight: 600,
							fontSize: '24px',
							lineHeight: '1.3'
						}}>{details.invoice.counterId}</div>
					</div>
					{details.invoice.files.length ?
						<div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
							<div onClick={() => setAttachmentMode(!attachmentMode)} style={{ background: 'var(--primary-color)', cursor: 'pointer', color: '#fff', padding: '5px 10px', borderRadius: 4, ...attachmentMode ? { background: '#000' } : {} }}>{attachmentMode ? 'Disable View' : 'Image View'} </div>
						</div>
				: null}
				</div>
				{

					attachmentMode ?
						<ViewAttachment items={details.invoice.files} />
						:
						<>
							<TabSwtich tabs={tabs} />
						  {getTab === 10 ? (
							<div id="Invoice_Format" className='FadeRight-Effect' style={{ position: 'relative', paddingBottom: 100 }}>
							
									{
										!!creditNotes.length &&
										creditNotes.map((item, index) => {
											return (
												<div onClick={() => props.setCreditId(item.id)} key={index} style={{ backgroundColor: '#e4e1ec', display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: '15px', marginBottom: 10, borderRadius: 8, marginTop:'20px' }}>
													<div >
														<p style={{ fontSize: 14, fontWeight: 'bold' }}>{item.creditNoteId}</p>
														<p style={{ fontSize: 12 }}>Amount :{item.currency} {item.amount}</p>
														<p style={{ fontSize: 12 }}>Created At : {Moment(item.date).format('DD-MM-YYYY')}</p>
													</div>
													<div>
														<p style={{ background: 'rgb(13, 110, 253)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, padding: '4px 10px', color: "#fff", borderRadius: 4 }}>{item.status}</p>
													</div>
												</div>

											)
										})
									}
									{
										creditNote?
										<div onClick={()=>{
											props.submitCreditNote({label:details.id,value:details.invoice.counterId})
										}} style={{
											boxShadow:'rgb(1 1 1 / 2%) 4px 5px 3px',
											background:'rgb(240, 112, 24)',
											cursor:'pointer',
											border:'1px solid var(--border-color)',
											borderRadius:8,
											width:150,
											fontSize:12,
											textAlign:'center',
											padding:'10px 12px',
											color:"#fff",
											marginTop:20
										}}>
											+ Create Credit Note
									  	</div>
										:null
									}
							</div>
							) : null}
							{getTab === 11 ? (
							<div id="Invoice_Format" className='FadeRight-Effect' style={{ position: 'relative', paddingBottom: 100 }}>
								{
										!!debitNotes.length &&
										debitNotes.map((item, index) => {
											return (
												<div onClick={() => props.setDebitId(item.id)} key={index} style={{ backgroundColor: '#e4e1ec', display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: '15px', marginBottom: 10, borderRadius: 8, marginTop:'20px' }}>
													<div >
														<p style={{ fontSize: 14, fontWeight: 'bold' }}>{item.debitNoteId}</p>
														<p style={{ fontSize: 12 }}>Amount :{item.currency} {item.amount}</p>
														<p style={{ fontSize: 12 }}>Created At : {Moment(item.createdAt).format('DD-MM-YYYY')}</p>
													</div>
													<div>
														<p style={{ background: 'rgb(13, 110, 253)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, padding: '4px 10px', color: "#fff", borderRadius: 4 }}>{item.status}</p>
													</div>
												</div>

											)
										})
								}
								{
										debitNotePlugin?
										<div  onClick={()=>{
											props.submitDebitNote({label:details.id,value:details.invoice.counterId})
										}}  style={{
											boxShadow:'rgb(1 1 1 / 2%) 4px 5px 3px',
											background:'rgb(240, 112, 24)',
											cursor:'pointer',
											border:'1px solid var(--border-color)',
											borderRadius:8,
											width:150,
											fontSize:12,
											textAlign:'center',
											padding:'10px 12px',
											color:"#fff",
											marginTop:20
										}}>
											+ Create Debit Note
									  	</div>
										:null
									}
							</div>
							) : null}
							{getTab === 1 ?
								<div id="Invoice_Format" className='FadeRight-Effect' style={{ position: 'relative', paddingBottom: 100 }}>
									{integrationData && _.size(integrationData) > 0 && integrationData['postingSynced'] && (
										<div style={{ display: 'flex', marginTop: 10, justifyContent: 'flex-end', alignItems: 'center' }}>
											<div style={{ padding: '4px 8px', borderRadius: '5px', fontSize: '11px', color: '#fff', display: 'inline-flex', marginTop: '5px', background: 'rgb(78 185 135)' }}>Posting Synced successfully</div>
										</div>
									)}

									{_.includes(BAJAJ, tenant) && (details && details.integration && details.integration.postingFailed && details.integration.postingTriggered) &&
										<div onClick={() => {
											setRepost(true)
										}} style={{
											boxShadow: 'rgb(1 1 1 / 2%) 4px 5px 3px',
											background: 'rgb(240, 112, 24)',
											cursor: 'pointer',
											border: '1px solid var(--border-color)',
											borderRadius: 8,
											width: 150,
											fontSize: 12,
											textAlign: 'center',
											padding: '10px 12px',
											color: "#fff",
											marginTop: 20
										}}>
											Re-Post Invoice
										</div>
									}
									{
										_.includes(BAJAJ,tenant) ?
										<>
										<div onClick={() => {
											post(`vendor/invoicing/${id}/syncInvoiceToAutoTagGrn`,{},(e,r)=>{
												if(r) {
													onReload();
													showToast({
														type:'success',
														message:"Invoice Sync successfully"
													})
												} else {
													showToast({
														type:'error',
														message:e.data.message
													})
												}
											})
										}} style={{
											boxShadow: 'rgb(1 1 1 / 2%) 4px 5px 3px',
											background: 'rgb(140, 111, 24)',
											cursor: 'pointer',
											border: '1px solid var(--border-color)',
											borderRadius: 8,
											width: 200,
											fontSize: 12,
											textAlign: 'center',
											padding: '10px 12px',
											color: "#fff",
											marginTop: 20
										}}>
											Sync  Invoice to Auto Tag GRN
										</div>										 
										</>
										:null
									}
									{
										costAllocation && !costAllocationDone ?
											<div style={{
												marginTop: 20
											}}>
												<ToggleSwitch status={enableCostAllocation} callback={setEnableCostAllocation} label='Add Cost Allocation to Invoice' />
											</div>
											: null
									}
									{
										details.status === 'POHOLD' ?
											<div style={{ fontSize: '12px', background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center' }}>
												<svg style={{ marginRight: 10, fontSize: '16px' }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
													<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
													<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
												</svg>
												<span>This Invoice is on hold because of possible reason <br></br>
													<b> {grn.length>0 ? "1. Partial GRN is pending":"1. GRN is pending"} </b>. <br></br>
													<b>2. Check Bill Number in Dice and Original Invoice</b>.
													</span>
											</div>
											: <></>
									}

							{
										details.status === 'APPROVAL' &&  props.details && props.details.po && props.details.po.id ?
											<div style={{ fontSize: '12px', background: '#FDF0DD', borderRadius: '6px', marginTop: '20px', padding: '20px', width: '100%', display: 'flex', alignItems: 'center' }}>
												<svg style={{ marginRight: 10, fontSize: '16px' }} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
													<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
													<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
												</svg>
												<span> 
													<b>Three way match completed</b>.
													</span>
											</div>
											: <></>
									}



									{
										details.duplicates && details.duplicates.length ?
											<>
												<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px' }}>Duplicate Voucher Id</div>
												{details.duplicates.map((item) => {
													return (
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>
															<svg style={{ fontSize: '14px', marginRight: '10px', verticalAlign: 'middle', color: '#fd7e14', marginBottom: '2px' }} className="bi bi-exclamation-triangle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
																<path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
															</svg>
															{item}</span>
														</div>
													)
												})}
											</>
											: null
									}
									   {
											ocrData && Object.keys(ocrData).length  ?
										<div>
											<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>OCR data
									       </div>
											{Object.keys(ocrData).map((key, index) => {
											if (key !== 'success' && key !== 'sellerGst') {
												return (
													<p key={index}>
														{key}: <span>{ocrData[key]}</span>
													</p>
												);
											} else if (key === 'sellerGst') {
												return (
													<p key={index}>
														{ocrData[key].split('\n')[1]}
													</p>
												);
											}
											return null;
										})}
										</div>
										
										: null
									}
									 {ocrMismatches && ocrMismatches.length ? 
										<div>
										<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>OCR Mismatches
									    </div>
										{ocrMismatches.map((e) =>(
											<div  key={e} style={{ marginTop: '5px' }}>
											 {e}
											</div>
										  ))}
										</div>							
									  : null 
									 }
									<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Details
									</div>
									<div style={{ marginTop: '5px' }}><span
										style={{ color: 'var(--text-color)', marginRight: '3px' }}>Invoice Number:</span>{details.invoice.invoiceId}
									</div>
									<div style={{ marginTop: '5px' }}><span
										style={{ color: 'var(--text-color)', marginRight: '3px' }}>Category:</span> {details.category}</div>
									<div style={{ marginTop: '5px' }}><span style={{
										color: 'var(--text-color)',
										marginRight: '3px'
									}}>Amount:</span> {`INR ${numberWithCommas(details.invoice.amount)}`}</div>
									{
										(startDate&&endDate) ?
										<div style={{ marginTop: '5px' }}><span
										style={{ color: 'var(--text-color)', marginRight: '3px' }}>Invoice Period :</span>{`${moment(startDate).format("DD-MMM-YYYY")} to ${moment(endDate).format("DD-MMM-YYYY")}`}
									</div>
									:null
									}

									{details.taxInformation  && details.taxInformation && details.taxInformation.tdsCode && (
											<div style={{ marginTop: '5px' }}><span style={{
												color: 'var(--text-color)',
												marginRight: '3px'
											}}>Tds Section:</span> {details.taxInformation.tdsCode}</div>
									)}
									{
										details.invoice.predictedAmount ?
											<div style={{ marginTop: '5px' }}><span style={{
												color: 'var(--text-color)',
												marginRight: '3px'
											}}>Predicted Amount:</span> {`INR ${details.invoice.predictedAmount ? numberWithCommas(details.invoice.predictedAmount.toFixed(2)) : '-'}`}</div>
											: null

									}

									{
										enableCostAllocation ?
											<CostAllocation
												ref={COSTALLOCATION}
												totalAmount={details.invoice.amount}
												prevItems={[...invoices]}
												costAllocationType={allocationType}
												gstin={billGstIn}
											/>
											: null
									}

									{
										costAllocationDone ?
											<Allocation
												logs={details.invoice.logs||[]}
												type="vendor-invoice"
												reference={details.invoice.id}
												ref={ref => ALLOCATION.current = ref}
											/>
											: null
									}
									{
										props.status === 'COMPLETED' || props.status === 'CANCELLED' ?
											<>
												<div style={{
													fontSize: '14px',
													fontWeight: 'bold',
													margin: '30px 0px 15px 0px'
												}}>Information
												</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Vendor Name:</span> {details.vendor.name}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Invoice Date:</span> {details.invoice.date}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Invoice Number:</span> {details.invoice.invoiceId}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>GSTIN:</span> {details.vendor.gstin}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Email:</span> {details.vendor.email}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Billing Address:</span> {details.address.address}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Shipping Address:</span> {details.deliverTo.address}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>GST:</span> {details.taxInformation.gst}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>SGST/UTGST:</span> {details.taxInformation.sgst + details.taxInformation.utgst}
												</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>CGST:</span> {details.taxInformation.cgst}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>IGST:</span> {details.taxInformation.igst}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>TDS:</span> {details.taxInformation.tds}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Base Amount:</span> {details.taxInformation.baseAmount}</div>
												<div style={{ marginTop: '5px' }}><span style={{
													color: 'var(--text-color)',
													marginRight: '3px'
												}}>Payable Amount:</span> {details.taxInformation.paidAmount}</div>
											</>
											: <>
												<div style={{
													fontSize: '14px',
													fontWeight: 'bold',
													margin: '30px 0px 15px 0px'
												}}>Update Invoice
												</div>
												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														disabled={details.status !== 'APPROVAL'}
														label="Vendor Name"
														placeholder="Select vendor"
														value={getVendor}
														reset={() => {
															setSelectedVendorAddress('');
														}}
														select={vendorModal => {
															setVendor(vendorModal)
														}}
														add={{
															name: "Add New Vendor",
															link: "/app/vendor/contacts/new"
														}}
														onChange={val => {
															if (val.length > 1) {
																get(`/vendor/contacts/search?q=${val}`, (e, r) => {
																	setVendors(r.contacts.map(item => ({
																		value: item.id,
																		label: item.title,
																		des: item.email
																	})))
																})
															} else {
																setVendors([])
															}
														}}
														items={getVendors} />
													{
														getVendor === '' ? <></> :
															<div onClick={() => {
																updateInvoice('vendor')
															}} style={{
																height: '45px',
																width: '45px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																fontSize: '16px',
																cursor: 'pointer',
																color: 'var(--white-color)',
																backgroundColor: 'var(--primary-color)',
																zIndex: '111',
																marginBottom: '8px',
																marginLeft: '-45px'
															}}>
																<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
																	width="1em" height="1em" fill="currentColor"
																	viewBox="0 0 16 16">
																	<path
																		d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
																</svg>
															</div>
													}
												</div>

												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
												<SearchableSelect
														label="Vendor Address"
														disabled={details.status !== 'APPROVAL'}
														placeholder="Select address"
														value={selectedVendorAddress}
														reset={() => {
															setSelectedVendorAddress('');
														}}
														select={vendorModal => {
															setToggleAddress(true)
															setSelectedVendorAddress(vendorModal)
														}}
														onChange={val => {
															if (val.length > 1) {
																 setVendorAddressFilter(vendorAddress.filter((e) => JSON.stringify(e).toLowerCase().includes(val.toLowerCase())))
															} else {
																setVendorAddressFilter([])
															}
														}}
														items={vendorAddressFilter} />
														{
														(!toggleAddress)? <></> :
															<div onClick={() => {
																updateInvoice('vendorAddress')
															}} style={{
																height: '45px',
																width: '45px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																fontSize: '16px',
																cursor: 'pointer',
																color: 'var(--white-color)',
																backgroundColor: 'var(--primary-color)',
																zIndex: '111',
																marginBottom: '8px',
																marginLeft: '-45px'
															}}>
																<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
																	width="1em" height="1em" fill="currentColor"
																	viewBox="0 0 16 16">
																	<path
																		d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
																</svg>
															</div>
													}
												</div>
												<div style={{ width: '100%',  marginTop: 20 }}>
														<BankAdd  id={id} vendorId={details.vendor.id} vendor={details.vendor} bank={details.vendor.bank}
														 init={() => {
															onReload()
														}}
														
														/>
													</div>
												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														disabled={details.status !== 'APPROVAL'}
														label="Billing Address"
														placeholder="Select Office"
														value={getBillOffice}
														reset={() => {
															setBillOffice('');
														}}
														select={office => {
															setBillOffice(office)
														}}
														onChange={q => {
															if (q.length > 0) {
																let data = {q,p2p:"yes"};
																get(`/search/v1/offices`, (e, r) => {
																	if (r) {
																		setOffices(r.offices.map(item => ({
																			label: item.name,
																			value: item.id
																		})))
																	}
																}, data)
															} else {
																setOffices([])
															}
														}}
														items={getOffices} />
													{
														getBillOffice === '' ? <></> :
															<div onClick={() => {
																updateInvoice('bill')
															}} style={{
																height: '45px',
																width: '45px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																fontSize: '16px',
																cursor: 'pointer',
																color: 'var(--white-color)',
																backgroundColor: 'var(--primary-color)',
																zIndex: '111',
																marginBottom: '8px',
																marginLeft: '-45px'
															}}>
																<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
																	width="1em" height="1em" fill="currentColor"
																	viewBox="0 0 16 16">
																	<path
																		d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
																</svg>
															</div>
													}
												</div>
												<div style={{ width: '100%' }}>
													<FloatInputButton type="date" onSubmit={(val) => {
														updateInvoice('date', val)
													}} title='Invoice Date' disabled={details.status !== 'APPROVAL'} placeholder={Moment(details.invoice.date, "DD-MM-YYYY").format('YYYY-MM-DD')} />
												</div>
												<div style={{ width: '100%' }}>
													<FloatInputButton type="date" onSubmit={(val) => {
														updateInvoice('due', val)
													}} title='Due Date' disabled={details.status !== 'APPROVAL'} placeholder={details.dueOn? Moment(details.dueOn).format('YYYY-MM-DD'):''} />
												</div>

												<div style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
													<SearchableSelect
														label="Shipping Address"
														disabled={details.status !== 'APPROVAL'}
														placeholder="Select Office"
														value={getShipOffice}
														reset={() => {
															setShipOffice('');
														}}
														select={office => {
															setShipOffice(office)
														}}
														onChange={q => {
															if (q.length > 0) {
																let data = {q,p2p:"yes"};
																get(`/search/v1/offices`, (e, r) => {
																	if (r) {
																		setOffices(r.offices.map(item => ({
																			label: item.name,
																			value: item.id
																		})))
																	}
																},data)
															} else {
																setOffices([])
															}
														}}
														items={getOffices} />
													{
														getShipOffice === '' ? <></> :
															<div onClick={() => {
																updateInvoice('ship')
															}} style={{
																height: '45px',
																width: '45px',
																display: 'flex',
																justifyContent: 'center',
																alignItems: 'center',
																fontSize: '16px',
																cursor: 'pointer',
																color: 'var(--white-color)',
																backgroundColor: 'var(--primary-color)',
																zIndex: '111',
																marginBottom: '8px',
																marginLeft: '-45px'
															}}>
																<svg className="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg"
																	width="1em" height="1em" fill="currentColor"
																	viewBox="0 0 16 16">
																	<path
																		d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
																</svg>
															</div>
													}
												</div>


												<FloatInputButton onSubmit={(val) => {
													if(val.length>16) {
														showToast({
															type:'error',
															message:"Invoice Number cannot be greater than 16 characters"
														})
														return
													}
													updateInvoice('id', val)
												}} title='Invoice Number' disabled={details.status !== 'APPROVAL'} placeholder={details.invoice.invoiceId} />

												<div style={{ display: 'flex' }}>
													<div style={{ width: '50%' }}><FloatInputButton onSubmit={(val) => {
														updateInvoice('base', val)
													}} title='Base Amount ' placeholder={details.taxInformation.baseAmount} disabled={details.status !== 'APPROVAL' || (po || (props.details && props.details.po && isArray(props.details.po) && props.details.po.length>0))} />
													</div>
												{((details.po &&details.po.length) || (details.contractId && !tdsAtLineItemInContract) || (!details.contractId && !tdsAtLineItem)) &&  
													<div style={{ width: '50%', paddingLeft: '10px', marginTop: 20 }}>
														<Tds id={id} vendorId={details.vendor.id} value={details.taxInformation.tds} init={() => {
															onReload()
														}}
															disabled={details.status !== 'APPROVAL'}
														/>
													</div>
													}
												</div>
												<div style={{ display: 'flex' }}>
													<div style={{ width: '33%' }}><FloatInputButton onSubmit={(val) => {
														updateInvoice('sgst', val)
													}} title='SGST / UTGST '
														placeholder={details.taxInformation.sgst ? details.taxInformation.sgst.toFixed(2) : details.taxInformation.sgst}
														disabled={details.status !== 'APPROVAL' || !SGstEnable || (po || (props.details && props.details.po && isArray(props.details.po) && props.details.po.length>0))}
													/>
													</div>
													<div style={{ width: '33%', paddingLeft: '10px' }}><FloatInputButton
														
														onSubmit={(val) => {
															updateInvoice('igst', val)
														}} title='IGST ' placeholder={details.taxInformation.igst ? details.taxInformation.igst.toFixed(2) : details.taxInformation.igst} disabled={details.status !== 'APPROVAL' || (SGstEnable  && vendorGstIn && billGstIn) || (po || (props.details && props.details.po && isArray(props.details.po) && props.details.po.length>0))} /></div>
													<div style={{ width: '33%', paddingLeft: '10px' }}>
														<FloatInputButton
														onSubmit={(val) => {
															updateInvoice('cgst', val)
														}} title='CGST' placeholder={details.taxInformation.cgst ? details.taxInformation.cgst.toFixed(2) : details.taxInformation.cgst} disabled={details.status !== 'APPROVAL' || !SGstEnable|| (po || (props.details && props.details.po && isArray(props.details.po) && props.details.po.length>0))} /></div>
												</div>
												<FloatInputButton onSubmit={(val) => {
													updateInvoice('discount', val)
												}} title='Discount Percentage' disabled={details.status !== 'APPROVAL'} placeholder={details.taxInformation.discount} />
												<FloatInputButton onSubmit={(val) => {
													 if(val>10 || val < (-10)) {
														showToast({
															type:'error',
															message:"RoundOff amount is not allowed greater than 10 or lesser then -10"
														})
														return
													 } 
													updateInvoice('roundOff', val)
												}} title='RoundOff' disabled={details.status !== 'APPROVAL'} placeholder={details.taxInformation.roundOff} />
											</>
									}
									 <div  style={{
                                    display:'flex',
                                    alignItems:'center',
                                    justifyContent:'flex-start',
									marginTop:20,
                                }}>
									<input onClick={()=>{ onRcmChange(!rcm) }} type='checkbox' checked={rcm}  
										 style={{
											height:22,
											width:22}}
										/>
                                       <p style={{
                                        minHeight:35,
                                        fontSize: 13,
                                        color: "var(--text-color)",
                                        borderRight:'2px solid var(--border-color)',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',
										marginLeft:10,
										fontWeight:600,
                                     }}>
                                        {'RCM Invoice'}
                                    </p>
									</div>
									<div
										style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 15px 0px' }}>Attachment
									</div>
									<div style={{
										display: 'flex',
										marginLeft: '-15px',
										flexWrap: 'wrap',
										maxHeight: '250px',
										overflowY: 'scroll'
									}}>


										<MultipleAttachmentUploaderModal onUpload={filesData => {
											let count = 0;
											filesData.forEach(async (i, index) => {
												setTimeout(async () => {
													await post(`/vendor/invoicing/${id}/attachment`, { file: i.url, name: i.name }, (e, r) => {
														if (r) {
															count++;
															if (count === filesData.length) {
																onReload();
																showToast({
																	type: 'success',
																	message: 'File uploaded'
																})

															}

														} else if (e) {
															handleError(e);
														}
													});
												}, index * 100)

											})
										}} />

										{
											details.invoice.files.length > 0 ?
												<>
													{
														details.invoice.files.map((item, index) => {
															return (
																<div>
																	<PhotoTile key={`Photo-${index}`} link={item} />
																	<p style={{ textAlign: 'center', fontSize: 10, fontWeight: 'bold' }}># {index + 1}</p>
																</div>
															)
														})
													}


												</>

												: <></>
										}

									</div>
									<PhotoName attachments={details.invoice.files} fileNames={details.invoice.fileNames} />

									{status === 'APPROVAL' &&
										details.invoice.items.map((line, index) => {
											return (<div key={index}
											>
												<Form handler={ref => handleFormRef(ref, index)} title={line.name} service={line.service} />
											</div>)
										})
									}

									{
										status === 'APPROVAL' ?
											<div>
												<Form handler={ref => FORM.current = ref} />
											</div>
											: null
									}

									{
										status === 'APPROVAL' ?
											enableCostAllocation ?
												<div style={{ left: 'calc(100% - 650px)', right: 0, bottom: 0, background: '#fff', borderTop: '1px solid var(--border-color)', position: 'fixed', height: 55, width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
													<div onClick={() => {

														if (btnloading) {
															return;
														}

														let costAllocation = COSTALLOCATION.current.getValue();

														if (costAllocation === true) {
															showToast({
																type: 'error',
																message: "Please update the cost allocation"
															})
															return;
														}

														let costAllocationAtItems = {};
														invoices.forEach(i => {
															if (i.costAllocations) {
																costAllocationAtItems[i.id] = i.costAllocations
															}
														})

														let data = {
															costAllocation: {
																...costAllocation,
																costAllocationAtItems
															}
														}

														setBtnLoading(true);

														post(`/vendor/invoicing/${id}/invoicing.costAllocation.update`, data, (e, r) => {
															if (r) {
																setEnableCostAllocation(false);
																setBtnLoading(false);
																props.onReload()

															} else {
																setBtnLoading(false)
																showToast({
																	type: 'error',
																	message: e.data.message
																})
															}
														})

													}} className='btn btn-portlet-success' style={{ background: "#ffe4b3", color: '#ffa500', width: '100%', maxWidth: '620px' }}>
														{
															btnloading ?
																"Updating;.."
																:
																"Update Cost Allocation"
														}
													</div>
												</div>
												:
												<div style={{ left: 'calc(100% - 650px)', right: 0, bottom: 0, background: '#fff', borderTop: '1px solid var(--border-color)', position: 'fixed', height: 55, width: '100%', display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
													<ReadOnly>
														<div onClick={() => {
															if (hsnMandatorySingleLineInvoice) {
																let HSNExist = true;
																details.invoice.items.forEach(el => HSNExist = HSNExist && el.hsn);
																if (!HSNExist) {
																	showToast({
																		type: 'error',
																		message: "Please add HSN to all the line items."
																	})
																	return
																}
															}
															if(activeRooms.length>0){
																showToast({
																	type: 'error',
																	message: "Unable to approve invoice: A room is currently open. Please ensure all rooms are closed before proceeding."
																})
																return
															}
															if (btnloading) {
																return
															} else if (!costAllocationDone && mandatoryCostAllocation && costAllocation) {
																showToast({
																	type: 'error',
																	message: "CostAllocation is mandatory"
																})
																return;
															} else {
																approveHandler()
															}
															}} className='btn btn-portlet-success' style={{ background: "#E4EFE7", color: '#198754', width: '100%', maxWidth: '315px' }}>
															{
																btnloading ?
																	"Loading..."
																	:
																	"Approve"
															}
														</div>
													</ReadOnly>
													
													<ReadOnly>
														<div onClick={() => {
															setDecline(details.invoice.id)
														}} className='btn btn-portlet-success' style={{ background: "#ffdad4", color: '#ba1b1b', width: '100%', maxWidth: '315px', marginLeft: 10 }}>
															Decline
														</div>
													</ReadOnly>	
													
												</div>
											: null
									}
									{ 
									 checkTypeAsAdmin(details && details.owner,status)  ? 
								      <>
									   {/* <div onClick={()=>{
								 	   	setWithdraw(true)
									}} style={{
										background: '#FF532E',
										color: '#fff',
										textAlign: 'center',
										fontSize: 15,
										padding: 15,
										marginTop: 25,
										fontWeight: 600,
										cursor: 'pointer',
										borderRadius:4
									}}>Withdraw Invoice</div> */}
									  </>
									: null
									}
								</div> : <></>
							}

							{getTab === 2 ?
								<>
									<div id="Invoice_Insight" className='FadeRight-Effect'>
										{
											irnData ? <IrnDetails data={irnData} /> : null
										}
										<div
											style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Insights
										</div>
										{
											details.tags ? <>
												{Object.entries(details.tags).map(renderTags)}
												{details.status === "APPROVAL" &&
													<>
														<>
															<h3 style={{ marginTop: '10px' }}>{props.details.tags && props.details.tags['inv_type']?props.details.tags['inv_type']==='single'?'Invoice Service Form':'Invoice Category Form':'Invoice Category Form'}</h3>
														</>
														{
															InvoiceForm.map(renderInvoiceCustomForm)
														}
														{Object.entries(customTags)
															.filter(([key]) => key === 'cost_center' || key === 'profit_center')
															.map(([key, value]) => (
																<FloatInputButton
																	title={key}
																	type={'text'}
																	placeholder={value}
																	disabled
																/>
														))}
													</>
												}
											</> : <></>
										}

										{
											details.invoice.items.filter(i => i.data.length > 0).map(mapLineItemTag)
										}
										<div
											style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>View
										</div>
										{
											details.views && details.views.map(mapView)
										}
									</div>
									<div style={{display: 'flex'}}>
										<div style={{width: '33%'}}><FloatInputButton onSubmit={(val) => {
											updateInvoice('sgst', val)
										}} title='SGST / UTGST '
											placeholder={details.taxInformation.sgst?details.taxInformation.sgst.toFixed(3):details.taxInformation.sgst}
											disabled={details.status !== 'APPROVAL'}
											/>
										</div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											onSubmit={(val) => {
												updateInvoice('igst', val)
											}} title='IGST ' placeholder={details.taxInformation.igst?details.taxInformation.igst.toFixed(2):details.taxInformation.igst} disabled={details.status !== 'APPROVAL'}/></div>
										<div style={{width: '33%', paddingLeft: '10px'}}><FloatInputButton
											onSubmit={(val) => {
												updateInvoice('cgst', val)
											}} title='CGST' placeholder={details.taxInformation.cgst ? details.taxInformation.cgst.toFixed(3) : details.taxInformation.cgst} disabled={details.status !== 'APPROVAL'}/></div>
									</div>
								</> : <></>
							}
							{getTab === 3 ?
								<div id="Invoice_Timeline" className='FadeRight-Effect'>
									<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Timeline
									</div>
									<Track stages={
										[...details.timelines.map((log, index) => {
											let eaObject = log.eaUser ? JSON.parse(log.eaUser) : undefined;
											let DESC = <div><b>{log.name}</b><br />
												<div style={{ marginTop: '5px' }}>Type: {log.type}</div>
												{log.status && 
												<div style={{ marginTop: '5px' }}>Status: {log.status}</div>
												}
												{
													(log.status==="REVERTED" && log.revertedTo)?<div style={{ marginTop: '5px' }}>Reverted to : {log.revertedTo}</div>:null
												}
												<div style={{ marginTop: '5px' }}>
													{log.actionTime && (log.status !== 'PENDING' && excludeStatus.includes(log.status)) ?
														`${_.capitalize(log.status)} on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}`
														: log.actionTime && log.status !== 'PENDING' && log.type !== 'start' ?
															`Approved on: ${Moment(log.actionTime).format(" hh:mm a DD MMM YY")}`
															:
															`Sent on: ${Moment(log.time).format("hh:mm a DD MMM YY")}`
													}
												</div>
												{
													eaObject && 
													<>
														<div style={{ marginTop: '5px' }}>Ea-User: {eaObject.ownerName}</div>
														<div style={{ marginTop: '5px' }}>Ea-UserId: {eaObject.ownerId}</div>
													</>
												}
											</div>
											return (
												{
													title: log.email,
													desc: DESC,
													status: log.type === 'start' || log.type === 'scanner-end' || (log.type === 'scanner-start' && details.timelines.length > (index + 1)) ? true : log.status ? timelineLogStatus.includes(log.status): log.name === 'Finance Action' || log.type === 'Completed'|| log.name === 'COMPLETED'? true : false
												})
										})]
									} />
								</div> : <></>
							}
							{getTab === 12 ?
								<div id="Invoice_Logs" className='FadeRight-Effect'>
									<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Logs</div>
									<div style={{"marginBottom":"1rem"}}>
										{
											details.invoice.logs.map((item, index)=>{
												return(
													<div key={`comments-${index}`}><span style={{color: '#666', marginRight: '5px'}}>{Moment(item.time).format(" hh:mm a DD MMM YY")}</span>: {item.log} - <span>by : {item.owner.ownerName}</span>
													<div>Remarks : {item.remarks}</div>
													</div>
												)
											})
										}
									</div>
								</div> : <></>
							}
							{
								getTab === 4 ?
									<div id="Settlements" className="FadeRight-Effect">
										{
											Settlements ?
												<div style={{ marginTop: 30 }}>
													<KeyValue title="LedgerId" value={Settlements.ledgerId} />
													<KeyValue title="Created At" value={Moment(Settlements.createdAt).format(settlementFormate)} />
													<KeyValue title="Currency" value={Settlements.currency} />
													{
														Settlements && Settlements.attrs && Settlements.attrs.payment_date ?
														<KeyValue title="Payment date" value={Settlements.attrs.payment_date} />
														:null
													}
													{Settlements.attrs && Settlements.attrs.key && Settlements.attrs.key === "payment_date" && (
														<KeyValue title="Payment Date" value={Settlements.attrs.value && moment(Settlements.attrs.value).format('DD-MM-YYYY')} />
													)}
													{Settlements.attrs && Settlements.attrs.utr && (
														<KeyValue title="Utr" value={Settlements.attrs.utr} />
													)}
												</div>
												: null
										}
										{
											Settlements ?
												Settlements.settlements.length ?
													<div className='FadeRight-Effect'>
														<div style={{ marginTop: 30 }}><span style={{ color: '#666', marginRight: '3px' }}>Ledger ID: </span>{Settlements.ledgerId}</div>
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Amount: </span>{Settlements.currency} {Settlements.amount}</div>
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Created On: </span>{moment(Settlements.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Remarks: </span>{Settlements.attrs.remarks}</div>
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Virtual Payout: </span>{Settlements.attrs['virtual.payout']}</div>
														<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Reference: </span>{Settlements.attrs.reference}</div>

														<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px', marginBottom: '20px' }}>Settlements</div>
														{
															Settlements.settlements.length ?
																Settlements.settlements.map((item, index) => {
																	return (
																		<div style={{ padding: '10px 20px', border: '1px solid var(--border-color)', position: 'relative', borderRadius: '6px', marginTop: '15px' }}>
																			<div key={`info-${index}`} style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Created On: </span>{moment(item.createdAt).format('HH:MM a DD-MM-YYYY')}</div>
																			<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Amount: </span>{item.currency} {item.amount}</div>
																			<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Ledger ID: </span>{item.ledgerId}</div>
																			<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Type: </span>{item.type}</div>
																			{
																				item.type === 'INVOICES' || item.type === 'POADVANCE' ?
																					<>
																						<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>TDS Amount: </span>{item.tdsAmount}</div>
																						<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Paid Amount: </span>{item.paidAmount}</div>
																					</>
																					: <></>
																			}
																			<div style={{ marginTop: '5px' }}><span style={{ color: '#666', marginRight: '3px' }}>Transferred: </span>{item.transferred ? 'Yes' : 'No'}</div>

																			<div style={{ position: 'absolute', cursor: 'pointer', right: 10, top: 10, color: 'var(--red-color)' }}>
																				{item.type === 'PARTIAL' && !item.transferred ?
																					<div onClick={() => { setRecallPartial(item) }}>Recall</div>
																					: null
																				}
																			</div>
																		</div>
																	)
																})
																: <div style={{ padding: '20px', border: '1px solid var(--border-color)', borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Information available</div>
														}
													</div>
													: <div style={{ padding: '20px', border: '1px solid var(--border-color)', marginTop: 20, borderRadius: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Information available</div>
												: <Spinner />
										}
										{
											Settlements ?
												<div className='FadeRight-Effect'>
													{
														!Settlements.settled ?
															<>
																<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Partial Payment</div>
																<span style={{
																	fontSize: 14,
																	fontWeight: 600,
																	cursor: 'pointer',
																	color: 'var(--primary-color)',
																}} onClick={() => {
																	setPartial(true)
																}}>+ Add Partial Payment</span>
															</>
															:
															null

													}
												</div>
												: null
										}
										{
											details.status === 'APPROVAL' && allIndependentCN.length > 0 &&
											<>
												<div style={{ fontSize: '14px', fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Sync Credit Note</div>
												{
													allIndependentCN.map((item, index) => {
														return (
															<div key={index} style={{ backgroundColor: '#e4e1ec', display: 'flex', justifyContent: 'space-between', cursor: 'pointer', padding: '15px', marginBottom: 10, borderRadius: 8, marginTop: '20px' }}>
																<div >
																	<p style={{ fontSize: 14, fontWeight: 'bold' }}>{item.creditNoteId}</p>
																	<p style={{ fontSize: 12 }}>Amount :{item.currency} {numberWithCommas(item.amount)}</p>
																	<p style={{ fontSize: 12 }}>Created At : {Moment(item.createdAt).format('DD-MM-YYYY')}</p>
																	<p onClick={()=>syncCreditNote(item)} style={{background: 'var(--primary-color)', cursor: 'pointer', color: 'rgb(255, 255, 255)', padding: '5px 5px', borderRadius: '4px',width:'100px',textAlign:'center',marginTop:'12px'}}>Sync</p>
																</div>
																<div>
																	<p style={{ background: 'rgb(13, 110, 253)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, padding: '4px 10px', color: "#fff", borderRadius: 4 }}>{item.status}</p>
																</div>
															</div>

														)
													})
												}
											</>
										}

										{
											details.status === 'APPROVAL' && details.invoice.advances.length > 0 ?

												<>
													<div style={{ fontSize: '14px', marginBottom: 20, fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Select Advance to settled this invoice</div>
													<NormalTable
														headers={[
															{
																key: "code",
																name: "Details",
																weight: 3,
															},
															{
																key: "amount",
																name: "Amount",
																weight: 2,
															},

														]}
														data={details.invoice.advances.filter(val => val.transferred)}
														mapItem={(item, index) => {
															let active = item.ledgerId === advance;
															return (
																<div key={index} className="border-bottom fintrip-department-item table-item">
																	<div className="linear-layout horizontal">
																		<div style={{ flex: 3 }}>
																			<div style={{
																				display: 'flex',
																				alignItems: 'center'
																			}}>
																				<div onClick={() => {
																					setAdvance(item.ledgerId);
																				}} style={{ height: 15, cursor: 'pointer', width: 15, border: '1px solid var(--border-color)', ...active ? { background: 'var(--success-color)', borderColor: 'var(--success-color)' } : { borderColor: 'var(--border-color)' } }} />
																				<div style={{ marginLeft: 10 }}>
																					<p onClick={(e) => {
																						e.stopPropagation();
																						setLedger(item.ledgerId);
																					}} className="top" style={{ fontSize: 14, cursor: 'pointer' }}>{item.ledgerId}</p>
																					<p className="bottom" style={{ fontSize: 12, marginTop: 5 }}>Type : {item.type}</p>
																				</div>
																			</div>
																		</div>
																		<div style={{ flex: 2 }}>
																			{item.currency} {item.amount}
																		</div>
																	</div>
																</div>
															)
														}}
													/>
													{advance ?
														<p style={{
															color: 'var(--white-color)',
															fontSize: 14,
															background: 'var(--primary-color)',
															padding: "8px 20px",
															borderRadius: 2,
															cursor: 'pointer',
															width: 150,
															textAlign: 'center',
															marginTop: 20
														}} onClick={() => {
															post(`settlements/settlements/${advance}/invoice/${id}/tagV2`, {}, (e, r) => {
																if (r) {
																	props.onReload();
																	fetchSettlements(id);
																	showToast({
																		type: 'success',
																		message: "Advance settled successfully against invoice"
																	})
																} else {
																	showToast({
																		type: 'error',
																		message: e.data.message
																	})
																}
															})
														}}>Settle Invoice </p>
														: null
													}
												</>
												: null





										}

										{
											Settlements && Settlements.advances && Settlements.advances.length ?
												<>
													<div style={{ fontSize: '14px', marginBottom: 20, fontWeight: 'bold', margin: '30px 0px 10px 0px' }}>Select Advance to settled this invoice</div>
													<NormalTable
														headers={[
															{
																key: "code",
																name: "Details",
																weight: 3,
															},
															{
																key: "amount",
																name: "Amount",
																weight: 2,
															},

														]}
														data={Settlements.advances.filter(val => val.transferred)}
														mapItem={(item, index) => {
															let active = item.ledgerId === advance;
															return (
																<div key={index} className="border-bottom fintrip-department-item table-item">
																	<div className="linear-layout horizontal">
																		<div style={{ flex: 3 }}>
																			<div style={{
																				display: 'flex',
																				alignItems: 'center'
																			}}>
																				<div onClick={() => {
																					setAdvance(item.ledgerId);
																				}} style={{ height: 15, cursor: 'pointer', width: 15, border: '1px solid var(--border-color)', ...active ? { background: 'var(--success-color)', borderColor: 'var(--success-color)' } : { borderColor: 'var(--border-color)' } }} />
																				<div style={{ marginLeft: 10 }}>
																					<p onClick={(e) => {
																						e.stopPropagation();
																						setLedger(item.ledgerId);
																					}} className="top" style={{ fontSize: 14, cursor: 'pointer' }}>{item.ledgerId}</p>
																					<p className="bottom" style={{ fontSize: 12, marginTop: 5 }}>Type : {item.type}</p>
																				</div>
																			</div>
																		</div>
																		<div style={{ flex: 2 }}>
																			{item.currency} {item.amount}
																		</div>
																	</div>
																</div>
															)
														}}
													/>
													{advance ?
														<p style={{
															color: 'var(--white-color)',
															fontSize: 14,
															background: 'var(--primary-color)',
															padding: "8px 20px",
															borderRadius: 2,
															cursor: 'pointer',
															width: 150,
															textAlign: 'center',
															marginTop: 20
														}} onClick={() => {
															post(`settlements/settlements/${advance}/invoice/${id}/tag`, {}, (e, r) => {
																if (r) {
																	props.onReload();
																	fetchSettlements(id);
																	showToast({
																		type: 'success',
																		message: "Advance settled successfully against invoice"
																	})
																} else {
																	showToast({
																		type: 'error',
																		message: e.data.message
																	})
																}
															})
														}}>Settle Invoice </p>
														: null
													}
												</>
												: null
										}

										{
											details.status === 'COMPLETED' && Settlements && !Settlements.settled?
											(integrationData && _.size(integrationData) > 0 && integrationData['postingSynced'])?null:<div style={{ marginTop: 10 }}>
													
													<p style={{
														color: 'var(--white-color)',
														fontSize: 14,
														background: 'var(--red-color)',
														padding: "5px 12px",
														borderRadius: 4,
														cursor: 'pointer',
														position: 'absolute',
														right: 100,
														top: 30
													}} onClick={() => { setRecall(true); }}>
														Recall Invoice
													</p>
												</div>
												:
												null
										}


									</div> :
									null
							}
							{
								getTab === 5 ?
									<div id="Po" className="FadeRight-Effect">
										<PODetails id={props.details.po.id} poId={props.details.po.poId} invoiceId={id} multiPos={props.details.po}/>
									</div> : undefined
							}
							{
								getTab === 6 ?
									<div className='FadeRight-Effect'>
										<SupportTicket id={id} type="vendor-invoice" />
									</div>
									: null
							}
							{
								getTab === 7 ?
									<>
										{
											integrationData && Object.keys(integrationData).length ?
												<Integration
													type="vendor-invoice"
													reference={details.invoice.id}
													integrationData={integrationData}
													refresh={props.onReload}
												/>

												: null
										}
									</>
									: null
							}
							{
								getTab === 8 ?
									<div className='FadeRight-Effect'>
										<TwoBRecon id={id} details={details} />
									</div>
									: null
							}
							{
								getTab === 9 ?
									<div className='FadeRight-Effect'>
										<Contract id={details.tags && details.tags['contract.id']} details={details} />
									</div>
									: null
							}


							<div style={{ height: '50px' }} />
						</>}
			</div>


			<Modal
				title='Add Partial Payment'
				des="Fill up the details"
				close={() => {
					setPartial(false)
				}}
				show={partial}
				onSave={() => {
					let amount = +AMOUNT.current.value;
					let remarks = REMARK.current.value;


					if (!amount) {
						showToast({
							type: 'error',
							message: 'Please enter the amount'
						})
						return
					}
					if (!remarks) {
						showToast({
							type: 'error',
							message: "Please enter the remarks"
						})
						return;
					}


					let data = {
						amount,
						remarks,
						currency: details.invoice.currency,

					}

					post(`settlements/settlements/invoice/${id}/partial`, { ...data }, (e, r) => {
						if (r) {
							setPartial(false)
							props.onReload()
							fetchSettlements(id)
							showToast({
								type: 'success',
								message: "Partial payment done successfully"
							})
						} else {
							handleError(e)
						}
					})

				}}
			>
				<FunctionalInput type="text" title="Amount" ref={AMOUNT} />
				<FunctionalInput type="text" title="Remarks" ref={REMARK} />

			</Modal>

			<Modal
				title='Recall Invoice'
				des="Enter a reason to recall it"
				close={() => {
					setRecall(false)
				}}
				show={recall}
				onSave={() => {
					let remarks = REMARKS1.current.value;

					if (!remarks) {
						showToast({
							type: 'error',
							message: "Please enter the remarks"
						})
						return;
					}


					let data = {
						remarks
					}

					post(`settlements/vendor/invoices/${id}/recall`, { ...data }, (e, r) => {
						if (r) {
							setRecall(false)
							fetchSettlements(id)
							props.onReload()
							setTab(1)
							showToast({
								type: 'success',
								message: "Invoice Recall  successfully"
							})
						} else {
							handleError(e)
						}
					})

				}}
			>
				<FunctionalInput type="text" title="Remarks" ref={REMARKS1} />

			</Modal>

			<Modal
				title='Recall Partial Payment'
				des="Enter a reason to recall it"
				close={() => {
					setRecallPartial(false)
				}}
				show={recallPartial}
				onSave={() => {
					let remarks = REMARKS.current.value;

					if (!remarks) {
						showToast({
							type: 'error',
							message: "Please enter the remarks"
						})
						return;
					}


					let data = {
						remarks,
					}

					post(`settlements/settlements/${recallPartial.id}/cancel`, { ...data }, (e, r) => {
						if (r) {
							setRecallPartial(false)
							fetchSettlements(id)
							props.onReload()
							setTab(1)
							showToast({
								type: 'success',
								message: "Partial Payment Recall  successfully"
							})
						} else {
							handleError(e)
						}
					})

				}}
			>
				<FunctionalInput type="text" title="Remarks" ref={REMARKS} />

			</Modal>

			{
				ledger ?
					<ViewLedger
						id={ledger}
						onClose={() => {
							setLedger(false)
						}}
					/>
					: null
			}

			{
				decline && decline !== 0 ? <DropdownModal
					title="Decline Invoice"
					button="Decline"
					close={() => {
						setDecline(undefined)
					}}
					dropdown="vendor-decline"
					onNext={val => {
						axios.delete(`${getBaseUrl()}/settlements/vendor/invoices/${decline}?remarks=${val}`, {
							headers: {
								"content-type": "text/html"
							},
						}).then(s => {
							showToast({
								type: 'success',
								message: 'Invoice declined successfully'
							})
							setDecline(undefined)
							props.onReload();
						}).catch(handleError)
					}}
				/> : null}
			 <Modal
				title='Withdraw Invoice'
				des="Enter a reason to Withdraw invoice"
				close={() => {
					setWithdraw(false)
				}}
				buttonName="Withdraw"
				show={withdraw}
				style={{
					save: {
					  height: "40px",
					  width: "100%",
					  display: "flex",
					  justifyContent: "center",
					  alignItems: "center",
					  backgroundColor: 'var(--red-color)',
					  borderColor: 'var(--red-color)'
					},
				  }}
				onSave={() => {
					let remarks = WITHDRAW_REMARK.current.value;
					if (!remarks) {
						showToast({
							type: 'error',
							message: "Please enter the remarks"
						})
						return;
					}

					let data = {
						remarks
					}

					post(`/vendor/invoicing/${id}/withdraw`, { ...data }, (e, r) => {
						if (r) {
							fetchSettlements(id)
							props.onReload()
							props.reloadInvoice &&  props.reloadInvoice()
							setTab(1)
							showToast({
								type: 'success',
								message: "Invoice Recall  successfully"
							})
							setWithdraw(false)
						} else {
							handleError(e)
						}
					})

				}}
			>
				<FunctionalInput type="text" title="Remarks" ref={WITHDRAW_REMARK} />
			</Modal>
			{repost ? 
			<Modal show={true}
				des=""
				buttonName="SAP Repost"
				style={{
					save: {
						padding: '8px 15px',
						width: '100%',
						textAlign: 'center'
					},
					close: {
						display: 'none'
					}
				}}
				title="SAP Re-post"
				close={() => {
					setRepost(false)
				}}
				onSave={onRepost}
				>
			<p>Are you sure to Repost Invoice ?</p>
		   </Modal>
		   : null }
		</SidePane>
	)

}
